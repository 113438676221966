import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Subscribe from 'components/shared/subscribe';

import Heading from '../heading';

const Content = ({ content, innerHTML, title, withoutSubscribeBlock, widthFull }) => (
  <>
    <article className="relative mt-10 md:mt-16 header-offset">
      <Container>
        <div
          className={classNames('grid', {
            'grid-cols-12 xl:gap-x-10': !widthFull,
            'grid-cols-1': widthFull,
          })}
        >
          <div
            className={classNames('relative col-start-1 col-end-13', {
              'lg:col-start-2 lg:col-end-12 xl:col-start-3 xl:col-end-11': !widthFull,
            })}
          >
            {title && (
              <Heading className="mb-6" tag="h1" size="lg">
                {title}
              </Heading>
            )}
            {innerHTML ? (
              <div className="content" dangerouslySetInnerHTML={{ __html: innerHTML }} />
            ) : (
              <div className="content">{content}</div>
            )}
          </div>
        </div>
      </Container>
    </article>
    {!withoutSubscribeBlock && <Subscribe />}
  </>
);

Content.propTypes = {
  content: PropTypes.string,
  innerHTML: PropTypes.string,
  title: PropTypes.string,
  withoutSubscribeBlock: PropTypes.bool,
  widthFull: PropTypes.bool,
};

Content.defaultProps = {
  content: null,
  innerHTML: null,
  title: null,
  withoutSubscribeBlock: false,
  widthFull: null,
};

export default Content;
