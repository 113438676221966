import { graphql } from 'gatsby';
import React from 'react';

import Hero from 'components/pages/case-study/hero';
import Content from 'components/shared/content';
import MainLayout from 'layouts/main';

const CaseStudy = ({
  data: {
    wpPage: {
      content,
      seo,
      title,
      acf: { image },
    },
  },
  pageContext,
}) => (
  <MainLayout seo={seo} pageContext={pageContext}>
    <Hero image={image} title={title} />
    <Content innerHTML={content} />
  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      content
      title
      url: uri
      acf {
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 968)
            }
          }
        }
      }
      ...wpPageSeo
    }
  }
`;

export default CaseStudy;
