import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const Hero = ({ image, title }) => (
  <section className="mt-10 md:mt-16 header-offset">
    <Container>
      <div className="grid grid-cols-12 xl:gap-x-10">
        <div className="relative col-start-1 col-end-13 lg:col-start-2 lg:col-end-12 xl:col-start-3 xl:col-end-11">
          <Heading size="lg" tag="h1" fontWeight="extra">
            {title}
          </Heading>
          {image && (
            <div className="h-auto 2xl:h-[544px] overflow-hidden mt-10">
              <GatsbyImage
                className="h-full"
                imgClassName="rounded-4xl xl:rounded-5xl"
                image={getImage(image.localFile.childImageSharp)}
                alt={image.altText}
                loading="eager"
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  </section>
);

export default Hero;
