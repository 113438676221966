import React, { useContext } from 'react';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import EmailInputForm from 'components/shared/email-input-form';
import Heading from 'components/shared/heading';
import { gtagSubscribeSubmissionSuccessEvent } from 'constants/constants';
import MainContext from 'context/main';
import SendIcon from 'icons/send.inline.svg';

import imageBottomRight from './images/image.svg';

const FORM_ID = 3;

const Subscribe = () => {
  const {
    sharedBlocks: {
      subscribe: {
        acf: { title, buttonText },
      },
    },
  } = useContext(MainContext);
  return (
    <section className="my-16 lg:my-28 xl:my-40" id="subscribeForm">
      <Container>
        <div className="relative max-w-[968px] mx-auto p-8 md:p-12 bg-secondary-1 rounded-4xl">
          <div className="relative z-10">
            <Heading
              className="text-xxl md:text-3xl xl:leading-tight xl:text-4xl max-w-[610px] xl:max-w-full"
              tag="h2"
              theme="white"
              fontWeight="extra"
            >
              {title}
            </Heading>
            <div className="max-w-full md:max-w-[452px] mt-6">
              <EmailInputForm
                className="subscribe-form"
                size="xs"
                buttonText={buttonText}
                formName="subscribe-form"
                formId={FORM_ID}
                gtagSuccessEvent={gtagSubscribeSubmissionSuccessEvent}
                backgroundColorInput="yellow"
              />
            </div>
          </div>
          <img
            className="hidden md:block max-w-[240px] lg:max-w-full absolute bottom-0 right-0 w-auto"
            src={imageBottomRight}
            alt=""
            loading="lazy"
            aria-hidden
          />
        </div>
      </Container>

      <Button
        className="fixed flex items-center bottom-7 right-4 md:right-6 lg:right-10 xl:right-7 z-20 max-h-[50px] !px-6 text-sm xl:!text-lg"
        theme="blue"
        to="#subscribeForm"
        size="md"
      >
        <SendIcon className="mr-2.5" />
        Subscribe
      </Button>
    </section>
  );
};

export default Subscribe;
